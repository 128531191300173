<template>
    <div
    v-if="product">
		<b-row>
			<b-col
			md="11"
			lg="6">
				<images></images>
			</b-col>
			<b-col
			md="11"
			lg="4">
				<product-data></product-data>
			</b-col>
		</b-row>
		<b-row>
			<b-col
			md="11"
			lg="10">
				<similars></similars>
			</b-col>
		</b-row>
    </div>
</template>
<script>
// Components
import Images from '@/components/buyer/product/components/images/Index'
import ProductData from '@/components/buyer/product/components/data/Index'
import Similars from '@/components/buyer/product/components/Similars'

import product from '@/mixins/product'
export default {
	mixins: [product],
	components: {
		Images,
		ProductData,
		Similars,
	},	
	created() {
		if (!this.product && this.view != '') {
			console.log('llamandooo')
			this.$store.dispatch('buyer_product/getBySlug', this.view)
		} else if (!this.product) {
			this.$router.push({name: 'Home'})
		}
	}
}
</script>