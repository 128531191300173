<template>
	<div
	class="amount">
		<span>
			Unidades
		</span>
		<div
		class="btn-content">
			<b-button
			@click="decrement"
			size="sm"
			variant="primary">
				<i class="icon-minus"></i>
			</b-button>
			<b-form-input
			type="number"
			min="1"
			v-model="amount"></b-form-input>
			<b-button
			size="sm"
			@click="increment"
			variant="primary">
				<i class="icon-plus"></i>
			</b-button>
		</div>
	</div>
</template>
<script>
import product from '@/mixins/product'
export default {
	mixins: [product],
	methods: {
		increment() {
			this.$store.commit('buyer_product/incrementAmount')
		},
		decrement() {
			this.$store.commit('buyer_product/decrementAmount')
		},
	}
}
</script>
<style lang="sass">
.amount
	display: flex
	flex-direction: columns
	align-items: center
	justify-content: space-between
	margin: 2em 0
	.btn-content
		display: flex
		flex-direction: columns
		.btn 
			&:first-child
				border-radius: 50px 0 0 50px
			&:last-child
				border-radius: 0 50px 50px 0
		input 
			border-radius: 0
			width: 4em
</style>