export default {
	computed: {
		product() {
			return this.$store.state.buyer_product.model
		},
		amount() {
			return this.$store.state.buyer_product.amount
		},
		loading() {
			return this.$store.state.buyer_product.loading
		},
	}
}