<template>
	<div class="p-25">
		<carousel
		class="m-b-10"
		v-if="product.images && product.images.length"
		navigationEnabled
		navigationNextLabel="<i class='icon-right'></i>"
		navigationPrevLabel="<i class='icon-left'></i>"
		paginationColor="#A9A9A9"
		:paginationPadding="5"
		loop
		:perPage="1"
		:adjustableHeight="false">
			<slide
			v-for="(image, index) in product.images"
			:data-index="index"
			:key="image.id">
				<vue-load-image>
					<img
					slot="image"
					class="slide-img b-r-1 s-2" 
					:src="image.image_url" 
					:alt="app_name+'-'+product.name">
					<img 
					slot="preloader"
					src="@/assets/spinner.gif"/>
					<div slot="error">
						Imagen no encontrada
					</div>
				</vue-load-image>
			</slide>
		</carousel>
	</div>
</template>
<script>
import VueLoadImage from 'vue-load-image'
import { Carousel, Slide } from 'vue-carousel'

import product from '@/mixins/product'
export default {
	mixins: [product],
	components: {
		VueLoadImage,
	    Carousel,
	    Slide
	},
}
</script>
<style scoped lang="sass">
.VueCarousel-slide
	display: flex
	align-items: center
	justify-content: center
.slide-img
	max-width: 100%
	@media screen and (max-width: 992px)
		max-height: 70vh
	@media screen and (min-width: 992px)
		max-height: calc(100vh - 150px)
	img 
		width: 100%
		height: 100% 
</style>