<template>
	<div>
		<b-button
		v-if="isProductInOrder(product)"
		block
		@click="removeFromOrder"
		variant="outline-primary">
			Quitar del pedido
		</b-button>
		<b-button
		v-else
		block
		@click="addToOrder"
		variant="primary">
			Agregar al pedido
		</b-button>
	</div>
</template>
<script>
import order from '@/mixins/order'
import product from '@/mixins/product'
export default {
	mixins: [order, product],
	methods: {
		removeFromOrder() {
			this.$store.commit('order/removeProduct', this.product)
		},
		addToOrder() {
			let product = {
				...this.product,
				amount: this.amount
			}
			this.$store.commit('order/addProduct', product)
			this.$cookies.set('product_'+product.id, product)
			this.$store.commit('product/setAmount', 1)
			this.$router.push({name: 'Home'})
		}
	}
}
</script>