<template>
	<div
	class="p-t-30 p-b-20">
		<div
		v-if="!loading">
			<product-data></product-data>
			<!-- <amount></amount> -->
			<!-- <button-order></button-order> -->
		</div>
		<data-skeleton
		v-else></data-skeleton>
	</div>
</template>
<script>
import ProductData from '@/components/buyer/product/components/data/Data'
import Amount from '@/components/buyer/product/components/data/Amount'
import ButtonOrder from '@/components/buyer/product/components/data/ButtonOrder'
import DataSkeleton from '@/components/buyer/product/components/data/DataSkeleton'

import product from '@/mixins/product'
export default {
	mixins: [product],
	components: {
		ProductData,
		Amount,
		ButtonOrder,
		DataSkeleton,
	},
}
</script>