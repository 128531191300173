<template>
	<div>
		<p
		class="name">
			{{ product.name }}
		</p>
		<p
		class="description"
		v-if="product.description">
			{{ product.description }}
		</p>
		<p
		class="price">
			{{ price(product.price) }}
		</p>
	</div>
</template>
<script>
import product from '@/mixins/product'
export default {
	mixins: [product],
}
</script>
<style scoped lang="sass">
.name
	font-size: 1.2em
	font-weight: bold
	text-align: left
	margin-bottom: .5em
.price
	font-size: 2em
	font-weight: 500
	text-align: left
	color: rgba(0, 0, 0, .7)
.description
	font-size: 1em
	font-weight: 500
	text-align: left
	color: rgba(0, 0, 0, .7)
	margin-bottom: 1em
.add-to-cart
	@media screen and (min-width: 992px)
		margin-top: 1em
	@media screen and (max-width: 992px)
		padding: 1em
		width: 100%
		height: 70px
		left: 0
		z-index: 90
		position: fixed
		background: #fff
		bottom: 0
.link-whatsapp
	text-align: left !important
	display: block
	margin-top: 1em
	color: #333
	&:hover 
		font-weight: bold
</style>