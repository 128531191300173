<template>
	<div
	v-if="similars.length">
		<p 
		class="title">
			Productos similares
		</p> 
		<display
		v-if="product"
		:show_created_at="false"
		display="cards"
		model_name="product"
		:models="similars"
		@clicked="toProduct"
		:set_model_on_click="false" />
	</div>
</template>
<script>
import ProductCard from '@/components/products/components/list/cards/ProductCard'

import products from '@/mixins/products'
import product from '@/mixins/product'
import buyer from '@/mixins/buyer'
export default {
	mixins: [product, buyer],
	components: {
		Display: () => import('@/common-vue/components/display/Index'),
		ProductCard,
	},
	computed: {
		categories() {
			return this.$store.state.category.models
		},
		similars() {
			let category = this.categories.find(category => {
				return category.id == this.product.category_id
			})
			return category.products.filter(product => {
				return product.id != this.product.id
			})
		}
	}
}
</script>