<template>
	<div
	v-if="product">
		<images
		v-if="!loading"></images>
		<images-skeleton
		v-else></images-skeleton>
	</div>
</template>
<script>
import Images from '@/components/buyer/product/components/images/Images'
import ImagesSkeleton from '@/components/buyer/product/components/images/ImagesSkeleton'

import product from '@/mixins/product'
export default {
	mixins: [product],
	components: {
		Images,
		ImagesSkeleton,
	}
}
</script>